// import moment from "moment";
import { cleanPhone } from "../helper/cleanPhone";
import toast from "../helper/toast";
import { getBiometricInfo, approveAccess } from "./api";
import { update_card } from "./update_card";
import { downloadDataAsTextFile } from "./createFile";

// Формируем контент для кнопки "Скачать JSON"
document.querySelector(".download_json").addEventListener("click", () => {
  const certificate = localStorage.getItem("certificate");
  downloadDataAsTextFile(certificate, "data.txt");
});

let backend_session_id = "";

document.getElementById("get_info").addEventListener("click", () => {
  const iin = document.getElementById("iin_input").value;
  const phoneNumber = document.getElementById("phone_input").value;

  const phoneRegex = /^(?:\+7|8) \(\d{3}\) \d{3}-\d{2}-\d{2}$/;

  if (iin.length < 12 || !phoneRegex.test(phoneNumber)) {
    document.querySelector(".error-get").style.display = "inline";
    return;
  }

  document.querySelector(".error-get").style.display = "none";

  getBiometricInfo({ iin: iin, phone_number: cleanPhone(phoneNumber) })
    .then(({ data }) => {
      backend_session_id = data.backend_session_id.backend_session_id;
      document.getElementById("modal").style.display = "block";

      const inputs = document.querySelectorAll(".js-otp-input");
      inputs[0].focus();
    })
    .catch((e) => {
      console.error(e);
      toast.fire({
        title: `Не найден`,
        icon: "error",
      });
    });
});

document.getElementById("send_code").addEventListener("click", (e) => {
  e.preventDefault();
  if (backend_session_id !== "") {
    const inputs = document.querySelectorAll(".js-otp-input");
    var code = "";
    inputs.forEach(function (input) {
      code += input.value;
    });

    if (code.length < 6) {
      document.querySelector(".error-msg").style.display = "inline";
      return;
    }

    document.querySelector(".error-msg").style.display = "none";

    approveAccess(backend_session_id, code)
      .then(({ data }) => {
        const certificate = data.data;
        localStorage.setItem("certificate", JSON.stringify(certificate));
        const adaptedData = {
          images: {
            face_photo: certificate.face_photo,
          },
          barcode: certificate.barcode,
          last_name: certificate.result_json.common.docOwner.lastName,
          first_name: certificate.result_json.common.docOwner.firstName,
          patronymic: certificate.result_json.common.docOwner.middleName,
          personal_number: certificate.result_json.common.docOwner.iin,
          date_of_birth: moment(
            certificate.result_json.domain.birthDate
          ).format("DD.MM.YYYY HH:mm:ss"),
          nationality: certificate.result_json.domain.nationality.nameRu,
          document_number: certificate.result_json.common.docNumber,
          address: certificate.result_json.domain.birthPlace.nameRu,
          authority: certificate.result_json.common.docIssuer.nameRu,
          date_of_issue: moment(
            certificate.result_json.domain.docIssuedDate
          ).format("DD.MM.YYYY HH:mm:ss"),
          date_of_expiry: moment(
            certificate.result_json.domain.docExpirationDate
          ).format("DD.MM.YYYY HH:mm:ss"),
        };
        localStorage.setItem("parsed", JSON.stringify(adaptedData));
        update_card(adaptedData);
        create_pdf_download_button(certificate.document_pdf);
        document.getElementById("modal").style.display = "none";
      })
      .catch((e) => {
        console.error(e);
        toast.fire({
          title: `Не верно указан код подтверждения`,
          icon: "error",
        });
      });
  }
});

document
  .getElementsByClassName("close-btn")[0]
  .addEventListener("click", function () {
    document.getElementById("modal").style.display = "none";
  });

// Формируем контент для кнопки "Скачать PDF"
function create_pdf_download_button(data) {
  const button = document.querySelector(".download_pdf");

  button.innerHTML = "";

  const downloadLink = document.createElement("a");
  downloadLink.href = "data:application/pdf;base64," + data;
  downloadLink.download = "id_card.pdf";
  downloadLink.textContent = "Скачать PDF";
  button.appendChild(downloadLink);
}
