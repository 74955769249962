import axios from "axios";

document.getElementById("qundiBanner").addEventListener("click", function () {
  axios
    .post("https://insurance.itanalytics.kz/api/v1/send_lead", {
      service_id: "EXTERNAL_SERVICE",
    })
    .then(function (response) {
      if (
        response.data &&
        response.data.data &&
        response.data.data.wizard_url
      ) {
        window.open(response.data.data.wizard_url, "_blank");
      }
    })
    .catch(function (error) {
      console.error("Error:", error);
    });
});
