// We import the CSS which is extracted to its own file by esbuild.
// Remove this line if you add a your own CSS build pipeline (e.g postcss).

// If you want to use Phoenix channels, run `mix help phx.gen.channel`
// to get started and then uncomment the line below.

// You can include dependencies in two ways.
//
// The simplest option is to put them in assets/vendor and
// import them using relative paths:
//
//     import "../vendor/some-package.js"
//
// Alternatively, you can `npm install some-package --prefix assets` and import
// them using a path starting with the package name:
//
//     import "some-package"
//

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
const url = window.location.pathname;
// console.log(url)

import { Socket } from "phoenix";
import "./phone_input";
import "./order_click";
import "./click_event";
import "./helper/search_input";
import "./helper/number_only";
import "./helper/eng_only";
import "./helper/modal_script";
import "./helper/active_link";
import "./helper/tabs";
import "./helper/lang";
// import "./helper/validate_edit_courses";
import "./helper/select";
// And connect to the path in "lib/kursonli_kurs_web/endpoint.ex". We pass the
// token for authentication. Read below how it should be used.

import "phoenix_html";
// Establish Phoenix Socket and LiveView configuration.
//import { Socket } from "phoenix"
import { LiveSocket } from "phoenix_live_view";
import topbar from "../vendor/topbar";

// Подключение скрипта только для страницы "Ордера"
if (url === "/worker/order") {
  import("./user_socket");
}

// Подключение скрипта только для страницы "Изменить курсы"
if (url === "/city" || url === "/") {
  import("./qundi");
  import("./index");
}

// Подключение скрипта только для основной страницы
if (url === "/worker/course") {
  import("./worker_courses");
}

// Подключение скрипта биометрии
if (url === "/worker/biometric") {
  import("./biometric/biometric");
  import("./biometric/camera");
  import("./biometric/otp-input");
}

let csrfToken = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");
let liveSocket = new LiveSocket("/live", Socket, {
  params: { _csrf_token: csrfToken },
});

// Show progress bar on live navigation and form submits
// topbar.config({ barColors: { 0: "#29d" }, shadowColor: "rgba(0, 0, 0, .3)" })
window.addEventListener("phx:page-loading-start", (info) => topbar.show());
window.addEventListener("phx:page-loading-stop", (info) => topbar.hide());

// connect if there are any LiveViews on the page
liveSocket.connect();

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
// >> liveSocket.disableLatencySim()
window.liveSocket = liveSocket;

document.querySelectorAll(".croppedText").forEach((element) => {
  const text = element.textContent;
  if (text.length > 20) {
    element.textContent = text.slice(0, 20) + "...";
  }
});

// Получение данных из сессии. Только для worker'a.
if (url.startsWith("/worker")) {
  $.ajax({
    url: "/worker/session",
    type: "GET",
    dataType: "json",
    success: function (session) {
      sessionStorage.setItem("worker", JSON.stringify(session));
    },
    error: function (xhr, status, error) {
      console.error("Запрос не выполнен: " + status + " " + error);
    },
  });
}
