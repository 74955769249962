// Сокет для сужения курсов по МИГу.
console.log("START")
import { Socket } from "phoenix";
const { usd_buy, usd_sale, eur_buy, eur_sale, rub_buy, rub_sale } = JSON.parse(
  document.querySelector(".main_courses").dataset.map
);
const { filial_id, city } = JSON.parse(
  document.querySelector(".session").dataset.map
);
const exceptions_filials = JSON.parse(
  document.querySelector(".exceptions_filials").dataset.map
);
// let priority_courses = JSON.parse(document.querySelector(".priority_courses").dataset.map)
let scrapped_courses = JSON.parse(
  document.querySelector(".start_courses").dataset.map
);

let socket = new Socket("/course_socket", {});
socket.connect({ filial_id: filial_id });
let channelUpdate = socket.channel(`course_update:lobby`);

channelUpdate
  .join()
  .receive("ok", (response) => {
    console.log("Подключено к каналу", response);
  })
  .receive("error", (error) => {
    console.error("Ошибка при подключении к каналу", error);
  });

// Выдает true если filial_id входит в список exceptions_filials и нынешнее время входит в промежутов с 20:00 по 8:00.
const is_exception = exceptions_filials.includes(filial_id);

// Возвращает true если текущее время входит в диапазон с 20:00 вечера по 08:00 утра.
let is_time_in_range = isTimeInRange();
function isTimeInRange() {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentUTCHour = currentHour;

  return currentUTCHour >= 20 || currentUTCHour < 8;
}

// Устанавливает ограничения в первый момент времени при загрузке страницы.
installLimitDiapason(
  scrapped_courses
  // priority_courses
);

// При каждом обновлении значений МИГ устанавливаем новые ограничения.
channelUpdate.on("update:scrapped_courses", (payload) => {
  scrapped_courses = payload;
  installLimitDiapason(
    payload

    // priority_courses
  );
});

// Устанавливает значения для купли/продажи основных валют.
function installLimitDiapason(
  scrapped_courses
  // priority_courses
) {
  // Только для Алматы.
//   if (city.name == "Алматы" && !is_time_in_range) {
//     let { usd_priority, eur_priority, rub_priority } = priority_courses;

//     let [_usdCurrency, usdBuy, usdSale] = scrapped_courses[usd_priority][0];
//     let [_eurCurrency, eurBuy, eurSale] = scrapped_courses[eur_priority][1];
//     let [_rubCurrency, rubBuy, rubSale] = scrapped_courses[rub_priority][2];

//     document.getElementById(`${usd_buy}`).max = usdBuy;
//     document.getElementById(`${usd_sale}`).min = usdSale;
//     document.getElementById(`${eur_buy}`).max = eurBuy;
//     document.getElementById(`${eur_sale}`).min = eurSale;
//     document.getElementById(`${rub_buy}`).max = rubBuy;
//     document.getElementById(`${rub_sale}`).min = rubSale;
//   }
}

// Меняет ограничения при смене значений приритета.
// TODO: Много переиспользования. В будущем отрефакторить!
const usd_select = document.querySelector("#priority\\|USD");
const eur_select = document.querySelector("#priority\\|EUR");
const rub_select = document.querySelector("#priority\\|RUB");


// usd_select.addEventListener("change", function () {
//   priority_courses.usd_priority = usd_select.value;
//   installLimitDiapason(scrapped_courses, priority_courses);

//   let [_usdCurrency, usdBuy, usdSale] =
//     scrapped_courses[priority_courses.usd_priority][0];
//   document.getElementById(`${usd_buy}`).value = usdBuy;
//   document.getElementById(`${usd_sale}`).value = usdSale;
// });

// eur_select.addEventListener("change", function () {
//   priority_courses.eur_priority = eur_select.value;
//   installLimitDiapason(scrapped_courses, priority_courses);

//   let [_eurCurrency, eurBuy, eurSale] =
//     scrapped_courses[priority_courses.eur_priority][1];
//   document.getElementById(`${eur_buy}`).value = eurBuy;
//   document.getElementById(`${eur_sale}`).value = eurSale;
// });

// rub_select.addEventListener("change", function () {
//   priority_courses.rub_priority = rub_select.value;
//   installLimitDiapason(scrapped_courses, priority_courses);

//   let [_usdCurrency, rubBuy, rubSale] =
//     scrapped_courses[priority_courses.rub_priority][2];
//   document.getElementById(`${rub_buy}`).value = rubBuy;
//   document.getElementById(`${rub_sale}`).value = rubSale;
// });

// Если значение buy и sale равно 85.0 отображает только 85
$(document).ready(function () {
  $(".number").each(function () {
    var currentValue = parseFloat($(this).val());

    // Проверить, является ли десятичная часть равной 0
    if (currentValue % 1 === 0) {
      // Удаляем десятичную часть и обновляем значение поля
      $(this).val(parseInt(currentValue));
    }
  });
});

// ##########################################################################################################################
// ##########################################################################################################################

// Все строки таблицы курсов.
const row = Array.from(document.querySelectorAll(".td-item"));
// Изначально кнопка активна, но при изменении курсов будет выполняться проверка валидности.
const buttonSubmit = document.querySelector("#submit-btn");

let recaptcha_status = !JSON.parse(
  document.querySelector(".recaptcha").dataset.map
);
document.querySelector("#recaptcha_solved").checked = recaptcha_status
if (recaptcha_status == false) {
  buttonSubmit.disabled = false;
} else {
  buttonSubmit.disabled = true;
}

if (check_is_delay()) {
  buttonSubmit.disabled = false;
} else {
  buttonSubmit.disabled = true;
}
// Флаг валидности, если он true, значит все значения валидны.
// Изначльно true, но если хотя бы одно из значений false, принимает значение false.
let is_valid = false;
let recaptcha = false;

// Перебор строк на поиск изменений.
row.forEach((el) => {
  const valueForBuyInput = el.querySelector(".buy");
  const valueForSaleInput = el.querySelector(".sale");

  differenceInputs(valueForSaleInput, valueForBuyInput);

  valueForBuyInput.addEventListener("input", handleInputChange);
  valueForSaleInput.addEventListener("input", handleInputChange);
});

// Перебор всех строк на валидность при изменении хотя бы одного значения.
// Также вызывается при прохождении капчи.
function handleInputChange() {
  is_valid = true;

  row.forEach((el) => {
    ensure_valid_values(el.querySelector(".buy"), el.querySelector(".sale"));
  });

  // Активирует/отключает кнопку "Сохранить изменения" после проверки всех строк.
  buttonSubmit.disabled = !is_valid;
}

// Проверка валидации введенных данных в таблицу.
function ensure_valid_values(buy, sale) {
  row_valid =
    check_buy_less_sale(buy, sale) &&
    check_only_positive_values(buy, sale) &&
    check_3_decimal_places(buy, sale) &&
    check_only_digits(buy, sale) &&
    check_zero(buy, sale) &&
    check_exception_filial(buy, sale) &&
    check_recaptcha();
    // check_is_delay();
  // Обновляет значение переменной после проверки каждой строки.
  is_valid = is_valid && row_valid;
  // console.log(check_buy_less_sale(buy, sale))
  // console.log(check_only_positive_values(buy, sale))
  // console.log(check_3_decimal_places(buy, sale))
  // console.log(check_only_digits(buy, sale))
  // console.log(check_zero(buy, sale))
  // console.log(check_exception_filial(buy, sale))
  // console.log(check_recaptcha())

  // console.log(check_is_delay())
  console.log(is_valid)
}

// Значение покупки всегда МЕНЬШЕ значения продажи.
function check_buy_less_sale(buy, sale) {
  if (sale.value != 0) {
    return parseFloat(buy.value) <= parseFloat(sale.value);
  }
  return true;
}

// Значения не могут быть отрицательными.
function check_only_positive_values(buy, sale) {
  return !(buy.value < 0 || sale.value < 0);
}

// Запрещены более 3 символов после запятой.
function check_3_decimal_places(buy, sale) {
  limitDecimalPlaces(buy);
  limitDecimalPlaces(sale);

  // Если в дробной части более 3 символов, устанавливает value с первыми тремя.
  function limitDecimalPlaces(input) {
    const parts = input.value.split(".");

    if (parts.length > 1) {
      const decimalPart = parts[1].substring(0, 3);
      input.value = `${parts[0]}.${decimalPart}`;
    }
  }

  return true;
}

// Запрещены все символы кроме цифр и одной запятой(точки).
function check_only_digits(buy, sale) {
  return true;
}

// Проверка на ноль.
function check_zero(buy, sale) {
  return true;
}

// Особые условия для определенных филиалов.
function check_exception_filial(buy, sale) {
  return true;
}

// Проверка капчи.
// Если капча отключена, то всегда возвращает true.
// Если капча включена, смотрит была ли она пройдена.
function check_recaptcha() {
  let recaptcha_status = !JSON.parse(
    document.querySelector(".recaptcha").dataset.map
  );
  return recaptcha_status ? true : recaptcha;
}

// Если возвращается true, то кнопка активна
// Если возвращается false, то кнопка не активна
function check_is_delay() {
  // let is_delay_status = JSON.parse(
  //   document.querySelector(".is_delay").dataset.map
  // );
  let is_delay_status = true;
  let delay_time = JSON.parse(
    document.querySelector(".delay_time").dataset.map
  );
  console.log(delay_time)

  if (!is_delay_status) {
    return true;
  }

  if (delay_time == null) {
    console.log("delay = null")
    return true;
  }
  
  if (city.name === "Алматы") {
    const currentTime = new Date();
    const delayTime = new Date(
      delay_time.year,
      delay_time.month - 1, // Months are zero-based in JavaScript
      delay_time.day,
      delay_time.hour,
      delay_time.minute,
      delay_time.second,
      parseInt(delay_time.microsecond.split(',')[0]) / 1000 // Convert microseconds to milliseconds
    );
    
    console.log(currentTime)
    console.log(delayTime)

    console.log(delayTime < currentTime)

    const currentHour = currentTime.getHours();
    const startHour = 9;
    const endHour = 17;

    console.log(currentHour);
    console.log(currentHour >= startHour && currentHour < endHour);

    // if (currentHour >= startHour && currentHour < endHour) {
      // if (delayTime < currentTime) {
      //   return true;
      // } else {
      //   return false;
      // }
    // } else {
    //   return true;
    // }
  }

  return true;
}

// Функция обратного вызова при успешном прохождении капчи.
window.recaptchaCallback = function () {
  recaptcha = true;
  // Если капча была прожата и значения валидны, то активирует кнопку.
  handleInputChange();
  document.querySelector("#recaptcha_solved").checked = recaptcha
};

function differenceInputs(saleInput, buyInput) {
  saleInput.addEventListener("input", handleInput);
  buyInput.addEventListener("input", handleInput);
  function handleInput() {
    let valueSale = parseFloat(
      saleInput.value.replace(/\s/, "").replace(",", ".") || 0
    );
    let valueBuy = parseFloat(
      buyInput.value.replace(/\s/, "").replace(",", ".") || 0
    );
    let issaleInputError = false;
    let isbuyInputError = false;
    if (
      isNaN(valueSale) ||
      !/^\d+(,\d{0,3})?(\.\d{0,3})?$/.test(saleInput.value) ||
      valueSale === 0
    ) {
      saleInput.style.border = "1px solid red";
      issaleInputError = true;
    } else {
      saleInput.style.border = "";
    }
    if (
      isNaN(valueBuy) ||
      !/^\d+(,\d{0,3})?(\.\d{0,3})?$/.test(buyInput.value)
    ) {
      buyInput.style.border = "1px solid red";
      isbuyInputError = true;
    } else {
      buyInput.style.border = "";
    }
    if (issaleInputError || isbuyInputError) {
      buttonSubmit.disabled = true;
      return;
    }
    const lastCharIndexOne = saleInput.value.length - 1;
    const isLastCharDotOne =
      saleInput.value[lastCharIndexOne] === "." ||
      saleInput.value[lastCharIndexOne] === ",";
    if (isLastCharDotOne && lastCharIndexOne > 0) {
      saleInput.style.border = "1px solid red";
      buttonSubmit.disabled = true;
      return;
    }
    const lastCharIndexTwo = buyInput.value.length - 1;
    const isLastCharDotTwo =
      buyInput.value[lastCharIndexTwo] === "." ||
      buyInput.value[lastCharIndexTwo] === ",";
    if (isLastCharDotTwo && lastCharIndexTwo > 0) {
      buyInput.style.border = "1px solid red";
      buttonSubmit.disabled = true;
      return;
    }
    if (valueSale < valueBuy && valueSale !== 0) {
      buyInput.style.border = "1px solid red";
      buttonSubmit.disabled = true;
    } else {
      buyInput.style.border = "";
    }
  }
}

// ##########################################################################################################################
// ##########################################################################################################################

// Модальное окно для удаления курса
$(".accept_delete_course").click(function (e) {
  e.preventDefault();
  const { course_id } = JSON.parse(this.dataset.map);
  Modalka.fire({
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    html: `
            <h1 class="title_without_margin text-left">
                <%= gettext("Действительно удалить валюту?") %>
            </h1>
            <div class="px-1">
                <div class="px-1">
                    <div class="text-left my-4">
                        <%= gettext("Это действие необратимо. Вы действительно хотите удалить этот курс?") %>
                    </div>
                    <a href="/worker/course/delete?id=${course_id}" class="btn_save my-2 pos w-fit" type="submit">
                        <%= gettext("Удалить") %>
                    </a>
                </div>
            </div>
          `,
  });
});

// ##########################################################################################################################
// ##########################################################################################################################
