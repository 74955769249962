import axios from "axios";

export const getBiometricInfo = (params) => {
  return axios.get("/worker/biometric/ajax_get_biometric_info", {
    params,
  });
};

export const docRecognition = (params) => {
  let csrfToken = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

  return axios.post("/worker/biometric/ajax_doc_recognition", params, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-csrf-token": csrfToken
    },
  });
};

export const approveAccess = (backend_session_id, code) => {
  return axios.get("/worker/biometric/ajax_online_access_aprove", {
    params: {
      backend_session_id: backend_session_id,
      code: code,
    },
  });
};
