export const downloadDataAsTextFile = (data, filename) => {
  const formattedJson = JSON.stringify(JSON.parse(data), null, 2); // Парсим JSON, чтобы отформатировать его
  const blob = new Blob([formattedJson], { type: "text/plain" });
  const url = window.URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = filename;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(downloadLink);
};
