// import moment from "moment";
import toast from "../helper/toast";

export const update_card = (data) => {
  const getClientCard = document.querySelector(".client");

  if (!data) {
    toast.fire({
      title: "Нет данных для обновления удостоверения личности",
      icon: "warning",
    });
    return;
  }

  getClientCard.style.display = "block";

  // Фото лица
  const facePhoto = document.querySelector(".face__photo img");
  if (data.images && data.images.face_photo) {
    facePhoto.src = "data:image/png;base64," + data.images.face_photo;
  }

  // barcode
  const barcode = document.querySelector(".client__barcode img");
  if (data.images && data.barcode) {
    barcode.src = "data:image/png;base64," + data.barcode;
  }

  // ФИО
  const clientName = document.querySelector(".client_name");
  const fullName = `${data.last_name || ""} ${data.first_name || ""} ${
    data.patronymic || ""
  }`.trim();
  clientName.innerText = fullName || "Отсутствует";

  // ИИН
  document.querySelector(".client_iin").innerText =
    data.personal_number || "Отсутствует";

  // Дата рождения
  const birthDate = document.querySelector(".client_birth_date");
  birthDate.innerText =
    moment(data.date_of_birth).format("DD.MM.YYYY") || "Отсутствует";

  // национальность
  document.querySelector(".client_nationality").innerText =
    data.nationality || "Отсутствует";

  // Номер документа
  document.querySelector(".client_doc_number").innerText =
    data.document_number || "Отсутствует";

  // Номер документа
  document.querySelector(".client_birth_place").innerText =
    data.address || "Отсутствует";

  // Орган выдачи
  document.querySelector(".client_issuer_name").innerText =
    data.authority || "Отсутствует";

  // Дата выдачи
  document.querySelector(".client_issued_date").innerText = data.date_of_issue
    ? moment(data.date_of_issue).format("DD.MM.YYYY")
    : "Отсутствует";

  // Срок действия
  document.querySelector(".client_expiration_date").innerText =
    data.date_of_expiry
      ? moment(data.date_of_expiry).format("DD.MM.YYYY")
      : "Отсутствует";
};
