// import moment from "moment";
import { getError } from "../helper/getError";
import toast from "../helper/toast";
import { docRecognition } from "./api";
import { update_card } from "./update_card";

const openCameraBtn = document.getElementById("toggle-camera");
const videoElement = document.getElementById("video-stream");
const scannerBox = document.getElementById("scanner-box");
const captureBtn = document.getElementById("capture-btn");
const sendBtn = document.getElementById("send-btn");
const scannedImage = document.getElementById("scanned-image");
const videoContainer = document.getElementById("video-container");
const closeCameraBtn = document.getElementById("close-camera");

let isCameraOpen = false;
let mediaStream = null;
let photoCount = 0;
let imageData = {};

const closeCamera = () => {
  videoContainer.style.display = "none";
  scannerBox.style.display = "none";
  scannedImage.innerHTML = "";
  photoCount = 0;
  imageData = {};
};

const toggleScanner = () => {
  if (isCameraOpen) {
    videoContainer.style.display = "block";
    scannerBox.style.display = "block";
  } else {
    closeCamera();
  }
};

document.addEventListener("keydown", function (e) {
  if (e.key === "Escape" && isCameraOpen) {
    toggleCamera();
  }
});

const toggleCamera = async () => {
  try {
    if (!isCameraOpen) {
      mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoElement.srcObject = mediaStream;
      isCameraOpen = true;
      toggleScanner();
    } else {
      mediaStream.getTracks().forEach((track) => track.stop());
      isCameraOpen = false;
      toggleScanner();
    }
  } catch (e) {
    console.error(e);
    toast.fire({
      title: getError(e),
      icon: "error",
    });
  }
};

const addDeleteIcon = (imageContainer, imageKey) => {
  const deleteIcon = document.createElement("img");
  deleteIcon.src = "/images/icons/x-circle.svg";
  deleteIcon.classList.add("delete-icon");
  deleteIcon.alt = "Delete";
  deleteIcon.addEventListener("click", () => {
    imageContainer.parentNode.removeChild(imageContainer);
    delete imageData[imageKey];
    photoCount--;
  });
  imageContainer.appendChild(deleteIcon);
};

const captureImage = () => {
  if (photoCount < 2) {
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    const imgData = canvas.toDataURL();
    const imgContainer = document.createElement("div");
    const imgElement = document.createElement("img");
    imgElement.src = imgData;
    imgContainer.appendChild(imgElement);
    scannedImage.appendChild(imgContainer);
    addDeleteIcon(imgContainer, `image_${photoCount + 1}`);
    photoCount++;

    imageData[`image_${photoCount}`] = imgData;
  } else {
    toast.fire({
      title: "Максимальное количество фото достигнуто",
      icon: "warning",
    });
  }
};

openCameraBtn.addEventListener("click", toggleCamera);
closeCameraBtn.addEventListener("click", toggleCamera);
captureBtn.addEventListener("click", captureImage);

const sendRequest = async () => {
  try {
    const formData = new FormData();
    if (Object.keys(imageData).length < 1) {
      toast.fire({
        title: "Нужно сначала сделать фото",
        icon: "warning",
      });
      return;
    }
    Object.keys(imageData).forEach((key, index) => {
      const imageFile = dataURItoBlob(imageData[key]);
      const imageKey = index === 0 ? "frontside_image" : "backside_image";
      formData.append(imageKey, imageFile, `${imageKey}.png`);
    });

    await docRecognition(formData)
      .then(({ data }) => {
        const certificate = data.data;
        const certificateData = {
          ...certificate,
          date_of_birth: certificate.date_of_birth
            ? moment.utc(certificate.date_of_birth, "DD.MM.YYYY").toISOString()
            : null,
          date_of_issue: certificate.date_of_issue
            ? moment.utc(certificate.date_of_issue, "DD.MM.YYYY").toISOString()
            : null,
          date_of_expiry: certificate.date_of_expiry
            ? moment.utc(certificate.date_of_expiry, "DD.MM.YYYY").toISOString()
            : null,
        };
        // dataJSON = { ...certificateData };
        localStorage.setItem("certificate", JSON.stringify(certificateData));
        // console.log(dataJSON, "dataJSON");
        // console.log(certificateData, "certificateData");
        update_card(certificateData);
        closeCamera();
      })
      .catch((e) => {
        console.log(e, "e");
        toast.fire({
          title: e,
          icon: "error",
        });
      });
  } catch (error) {
    console.error("Error:", error);
  }
};

const dataURItoBlob = (dataURI) => {
  const byteString = dataURI.split(",")[1];
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

sendBtn.addEventListener("click", sendRequest);
