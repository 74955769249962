// import { deepFind } from "./deepFind";

export const translateApi = (message) => {
  const objectTranslate = {
    "NotReadableError: Device in use":
      "Камера уже используется другим приложением",
    "DOMException: Device in use": "Камера уже используется другим приложением",
  };
  return objectTranslate[message] || message;
};

export const getError = (e) => {
  if (typeof e == "string") {
    return translateApi(e);
  }
  if (typeof e == "object") {
    return translateApi(e);
  }
};
