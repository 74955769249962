document.addEventListener('DOMContentLoaded', () => {
  const elements = [...document.querySelectorAll("[data-id]")];

  // Если значение buy равно 85.0 отображает только 85
  $(document).ready(function () {
    $(".buy").each(function () {
      var currentValue = parseFloat($(this).text());
      // Проверить, является ли десятичная часть равной 0
      if (currentValue % 1 === 0) {
        // Удаляем десятичную часть и обновляем значение поля
        $(this).text(parseInt(currentValue));
      }
    });
  });

  // Если значение sale равно 85.0 отображает только 85
  $(document).ready(function () {
    $(".sale").each(function () {
      var currentValue = parseFloat($(this).text());
      // Проверить, является ли десятичная часть равной 0
      if (currentValue % 1 === 0) {
        // Удаляем десятичную часть и обновляем значение поля
        $(this).text(parseInt(currentValue));
      }
    });
  });

  // Список всех id на странице по порядку.
  const ids = elements.map(element => element.dataset.id);

  // Смена валюты при изменении в select.
  const selectButton = document.querySelectorAll(".select_table")
  selectButton.forEach((el) => el.addEventListener('change', () => {
    let short_name = el.value
    let columnIndex = el.dataset.select

    params = {
      ids: ids,
      short_name: short_name
    }

    // Формируем корректный url.
    var queryString = Object.keys(params).map(function (key) {
      return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    $.ajax({
      url: "/ajax/new_column_by_short_name?" + queryString,
      type: 'GET',
      dataType: 'json',
      success: function (data) {
        create_new_table_with_select(data.new_courses, columnIndex)
      },
      error: function (xhr, status, error) {
        console.error('Запрос не выполнен: ' + status + ' ' + error);
      }
    });
  })
  );

  // Формирует новую отсортированную таблицу после выбора нового курса.
  function create_new_table_with_select(new_ids, columnIndex) {
    const [ids, best_buy, best_sale] = new_ids
    const table = document.querySelector('.data-table');
    const tableRows = Array.from(table.querySelectorAll(".one-item"));

    // Формирование новой таблицы.
    tableRows.forEach(row => {
      // Нахожим нужный элемент из списка ids.
      new_row = ids.find((el) => el.id == row.dataset.id)
      // Если элемент существует, заменить значения buy/sale.
      if (new_row != undefined) {
        row.classList.remove("!hidden")

        // Вытаскиваем все колонки и берем их значения buy/sale.
        let dataItem = row.querySelectorAll(".dataget");
        let cell = dataItem[columnIndex - 1];
        let buyElement = cell.querySelector('.buy');
        let saleElement = cell.querySelector('.sale');

        buyElement.textContent = new_row.buy
        saleElement.textContent = new_row.sale

        // Удаляем старые "лучшие курсы".
        buyElement.classList.remove('best_course');
        saleElement.classList.remove('best_course');

        // Добавляем новые "лучшие курсы".
        if (row.dataset.id == best_buy) {
          buyElement.classList.add('best_course');
        }

        if (row.dataset.id == best_sale) {
          saleElement.classList.add('best_course');
        }

      } else {
        // Скрываем строку.
        row.classList.add("!hidden")
      }
    });
  }


  // #######################################################################################
  // Обработка нажатия кнопки сортировки покупки.
  const sortButtonsPur = document.querySelectorAll('.sort_buy');
  sortButtonsPur.forEach(button => {
    button.addEventListener('click', () => {
      let order = button.dataset.order
      let columnIndex = button.dataset.number

      let short_name = document.querySelector(`[data-select="${columnIndex}"]`).value;

      get_new_ids_from_ajax("buy", short_name, order, columnIndex)

      reverse_sort_button(button)
    });
  });

  // Обработка нажатия кнопки сортировки продажи.
  const sortButtonsSale = document.querySelectorAll('.sort_sale');
  sortButtonsSale.forEach(button => {
    button.addEventListener('click', () => {
      let order = button.dataset.order
      let columnIndex = button.dataset.number
      let short_name = document.querySelector(`[data-select="${columnIndex}"]`).value;

      get_new_ids_from_ajax("sale", short_name, order, columnIndex)

      reverse_sort_button(button)
    });
  });


  // ajax-запрос нас сервер для получения нового отфильтрованного списка id.
  function get_new_ids_from_ajax(type, short_name, order, columnIndex) {

    params = {
      ids: ids,
      short_name: short_name,
      type: type,
      order: order
    }

    // Формируем корректный url.
    var queryString = Object.keys(params).map(function (key) {
      return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    $.ajax({
      url: "/ajax/sort_by_ids?" + queryString,
      type: 'GET',
      dataType: 'json',
      success: function (data) {
        create_new_table(data.new_ids, columnIndex)
      },
      error: function (xhr, status, error) {
        console.error('Запрос не выполнен: ' + status + ' ' + error);
      }
    });
  }

  // Формирует новую отсортированную таблицу по новому списку id.
  function create_new_table(new_ids, columnIndex) {
    const [ids, best_buy, best_sale] = new_ids
    const table = document.querySelector('.data-table');
    const tableRows = Array.from(table.querySelectorAll(".one-item"));

    // Создаем новую таблицу.
    const newTable = document.createElement('tbody');

    // Сортировка строк в порядке, определенном в списке ids.
    const sortedRows = [];

    // Сначала добавляем элементы, существующие в tableRows, в нужном порядке
    for (const id of ids) {
      const row = tableRows.find(row => row.dataset.id === id);
      if (row) {
        sortedRows.push(row);
      }
    }

    // Затем добавляем оставшиеся элементы из tableRows, которых нет в ids
    for (const row of tableRows) {
      if (!ids.includes(row.dataset.id)) {
        sortedRows.push(row);
      }
    }

    // Удаление строк старой таблицы.
    tableRows.forEach(row => row.parentNode.removeChild(row));

    // Формирование новой таблицы
    sortedRows.forEach(row => {
      const newRow = row.cloneNode(true);

      // Вытаскиваем все колонки и берем их значения buy/sale.
      let dataItem = row.querySelectorAll(".dataget");
      console.log(dataItem, 'dataItem')
      let cell = dataItem[columnIndex - 1];
      let buyElement = cell.querySelector('.buy');
      let saleElement = cell.querySelector('.sale');

      // Удаляем старые "лучшие курсы".
      buyElement.classList.remove('best_course');
      saleElement.classList.remove('best_course');

      // Добавляем новые "лучшие курсы".
      if (row.dataset.id == best_buy) {
        buyElement.classList.add('best_course');
      }

      if (row.dataset.id == best_sale) {
        saleElement.classList.add('best_course');
      }

      newTable.appendChild(newRow);
      document.querySelector('.data-table').appendChild(row);

    });
  }

  // Меняет data-order(asc/desc) и стрелку(вверх/вниз) на противоположенные.
  function reverse_sort_button(button) {
    const img = button.getAttribute('src');

    if (img.includes('down.svg')) {
      button.setAttribute('src', '/images/index/up.svg');
      button.setAttribute('data-order', 'asc');
    } else if (img.includes('up.svg')) {
      button.setAttribute('src', '/images/index/down.svg');
      button.setAttribute('data-order', 'desc');
    }
  }
});
